'use client';

import cx from 'classnames';
import { Squash as Hamburger } from 'hamburger-react';
import { useEffect, useState } from 'react';
import Button from '~/views/components/button';
import typography from '~/views/styles/typography.module.css';
import styles from './index.module.css';
const useIsScrolled = () => {
  const [isScrolled, setIsScrolled] = useState();
  useEffect(() => {
    const set = e => {
      setIsScrolled(e.target.scrollTop > 200);
    };
    window.addEventListener('scroll', set, true);
    return () => window.removeEventListener('scroll', set);
  }, []);
  return isScrolled;
};
const Nav = ({
  isScrolled,
  isDark
}) => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState();
  return <nav className={cx(typography.body2, styles.root, isScrolled && styles.isScrolled, isDark && styles.isDark, isHamburgerOpen && styles.isHamburgerOpen)}>
      <a href='/' className={styles.logo} />
      <div className={styles.hamburger}>
        <Hamburger role='button' aria-label='Humburger menu' toggled={isHamburgerOpen} toggle={() => setIsHamburgerOpen(!isHamburgerOpen)} />
      </div>
      <div className={styles.menu}>
        <div className={cx(styles.mainLinks, styles.links)}>
          <a href='/why'>Why SendingStone</a>
          <a href='/pricing'>Pricing</a>
          <a href='/discover'>Discover adventures</a>
        </div>
        <div className={styles.links}>
          <a href='/api/auth/login'>Log in</a>
          <Button size='l' color='red' href='/api/auth/signup'>
            Sign up
          </Button>
        </div>
      </div>
    </nav>;
};
const Wrapper = props => {
  const isScrolled = useIsScrolled();
  return <Nav {...{
    ...props,
    isScrolled
  }} />;
};
export default Wrapper;