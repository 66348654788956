import cx from 'classnames';
import Image from '~/views/components/image';
import typography from '~/views/styles/typography.module.css';
import CtaButton from '../cta-button';
import styles from './index.module.css';
const Main = () => <div className={cx(styles.root, false && styles.isSafari)}>
    <div className={styles.hero} style={{
    backgroundImage: 'url(/home-footer.webp)'
  }}>
      <div className={styles.heroInner}>
        <Image className={styles.logo} src='/logo.svg' width={192} height={52} alt='SendingStone logo' />
        <p className={cx(typography.body1, styles.cta)}>
          Adventure awaits, your friends are remote, and teleportation circles
          aren’t canon in this world. Playing online is the only option.
        </p>
        <h3 className={cx(typography.h3, styles.h3)}>What do you do?</h3>
        <form>
          <CtaButton className={styles.button} />
        </form>
      </div>
      <div className={styles.bottom}>
        <a href='https://twitter.com/sendingstoneapp' target='_blank' rel='noopener noreferrer'>
          <Image width={24} height={24} src='/icons/twitter.svg' alt='Twitter' />
        </a>
        <a href='https://www.reddit.com/user/sendingstoneapp' target='_blank' rel='noopener noreferrer'>
          <Image width={24} height={24} src='/icons/reddit.svg' alt='Reddit' />
        </a>
        <a href='https://www.youtube.com/channel/UCaAplEUCLrgO5s0pkw6fHrA' target='_blank' rel='noopener noreferrer'>
          <Image width={24} height={24} src='/icons/youtube-social.svg' alt='Youtube' />
        </a>
        <a href='https://www.tiktok.com/@sendingstone' target='_blank' rel='noopener noreferrer'>
          <Image width={24} height={24} src='/icons/tiktok.svg' alt='TikTok' />
        </a>
        <a href='https://www.instagram.com/sendingstoneapp/' target='_blank' rel='noopener noreferrer'>
          <Image width={24} height={24} src='/icons/instagram.svg' alt='Instagram' />
        </a>
        <a href='https://www.facebook.com/SendingStone' target='_blank' rel='noopener noreferrer'>
          <Image width={24} height={24} src='/icons/facebook.svg' alt='Facebook' />
        </a>
        <p>
          <a href='mailto:hello@sendingstone.com'>Email us</a> •{' '}
          <a href='/blog'>Blog</a> • <a href='/compendium'>Compendium</a> •{' '}
          <a href='/privacy'>Privacy</a> • <a href='/terms'>Terms</a> •{' '}
          <a href='/beyond20'>Beyond20</a> • <a href='/plan'>PLAN Gaming</a> •{' '}
          <a href='/credits'>Credits</a>
          <br />© {new Date().getFullYear()} SendingStone,{' '}
          <a href='http://www.craigspaeth.com' target='_blank' rel='noopener noreferrer'>
            Craig Spaeth
          </a>
          <br />
        </p>
      </div>
    </div>
    <script defer src='https://cloud.umami.is/script.js' data-website-id='0bbab2be-32d4-416e-9bd2-c9072a04e4fd' />
  </div>;
export default Main;