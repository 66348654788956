'use client'
import path from 'path'
import { getGPUTier } from 'detect-gpu'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import env from '~/lib/env'

const TEN_MB = 1_000_000 * 10

export const sentenceCase = (word) =>
  _.upperFirst(_.snakeCase(word).split('_').join(' '))

export const clipboardImage = (event) => {
  const items = (event.clipboardData || event.originalEvent.clipboardData).items
  const image = _.find(items, { type: 'image/png' })
  return image?.getAsFile()
}

export const resizeImg = (url, width = 1400, ext = '.webp') =>
  url.match(/^http/)
    ? `https://res.cloudinary.com/${
        env.CLOUDINARY_CLOUD_NAME
      }/image/fetch/w_${width}/${url.split('.').slice(0, -1).join('.') + ext}`
    : url

export const useIsScrolledDown = (num = 200) => {
  const [isDown, setIsDown] = useState(false)
  useEffect(() => {
    const playVideo = () => {
      if (document.body.scrollTop < num) return
      setIsDown(true)
      window.removeEventListener('scroll', playVideo)
    }
    window.addEventListener('scroll', playVideo, true)
    return () => window.removeEventListener('scroll', playVideo)
  }, [])
  return isDown
}

export const loadImageData = async (file) => {
  if (file.size > TEN_MB) throw new Error('File too large')
  if (!isImage(file.name) && !isVideo(file.name)) return
  const reader = new window.FileReader()
  const image = document.createElement(isVideo(file.name) ? 'video' : 'img')
  await new Promise((resolve) => {
    reader.addEventListener('load', () => {
      image.src = reader.result
      resolve()
    })
    reader.readAsDataURL(file)
  })
  return { url: reader.result, image }
}

export const loadImageUrl = (path) =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.src = path
    img.onload = () => resolve(img)
    img.onerror = (e) => reject(e)
  })

const videoExtensions = new Set([
  '3g2',
  '3gp',
  'aaf',
  'asf',
  'avchd',
  'avi',
  'drc',
  'flv',
  'm2v',
  'm3u8',
  'm4p',
  'm4v',
  'mkv',
  'mng',
  'mov',
  'mp2',
  'mp4',
  'mpe',
  'mpeg',
  'mpg',
  'mpv',
  'mxf',
  'nsv',
  'ogg',
  'ogv',
  'qt',
  'rm',
  'rmvb',
  'roq',
  'svi',
  'vob',
  'webm',
  'wmv',
  'yuv',
])

export const isVideo = (filePath) =>
  videoExtensions.has(path.extname(filePath).slice(1).toLowerCase())

const imageExtensions = new Set([
  '3dv',
  'ai',
  'amf',
  'art',
  'ase',
  'awg',
  'blp',
  'bmp',
  'bw',
  'cd5',
  'cdr',
  'cgm',
  'cit',
  'cmx',
  'cpt',
  'cr2',
  'cur',
  'cut',
  'dds',
  'dib',
  'djvu',
  'dxf',
  'e2d',
  'ecw',
  'egt',
  'emf',
  'eps',
  'exif',
  'fs',
  'gbr',
  'gif',
  'gpl',
  'grf',
  'hdp',
  'heic',
  'heif',
  'icns',
  'ico',
  'iff',
  'int',
  'inta',
  'jfif',
  'jng',
  'jp2',
  'jpeg',
  'jpg',
  'jps',
  'jxr',
  'lbm',
  'liff',
  'max',
  'miff',
  'mng',
  'msp',
  'nef',
  'nitf',
  'nrrd',
  'odg',
  'ota',
  'pam',
  'pbm',
  'pc1',
  'pc2',
  'pc3',
  'pcf',
  'pct',
  'pcx',
  'pdd',
  'pdn',
  'pgf',
  'pgm',
  'PI1',
  'PI2',
  'PI3',
  'pict',
  'png',
  'pnm',
  'pns',
  'ppm',
  'psb',
  'psd',
  'psp',
  'px',
  'pxm',
  'pxr',
  'qfx',
  'ras',
  'raw',
  'rgb',
  'rgba',
  'rle',
  'sct',
  'sgi',
  'sid',
  'stl',
  'sun',
  'svg',
  'sxd',
  'tga',
  'tif',
  'tiff',
  'v2d',
  'vnd',
  'vrml',
  'vtf',
  'wdp',
  'webp',
  'wmf',
  'x3d',
  'xar',
  'xbm',
  'xcf',
  'xpm',
])

export const isImage = (filePath) =>
  imageExtensions.has(path.extname(filePath).slice(1).toLowerCase())

export const getGpuTier = _.once(() => getGPUTier().catch(() => ({ tier: 1 })))
