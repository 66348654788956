'use client';

import cx from 'classnames';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import styles from './index.module.css';
export const useTrialRoomId = () => {
  const [id, setId] = useState(uuid());
  useEffect(() => {
    setId(uuid());
  }, []);
  return id;
};
const Button = ({
  className
}) => <a href={`/room/${useTrialRoomId()}`} rel='nofollow' className={cx(className, styles.root)}>
    Open free trial room
  </a>;
export default Button;