'use client';

import cx from 'classnames';
import Image from '~/views/components/image';
import typography from '~/views/styles/typography.module.css';
import { resizeImg } from '../../../lib/utils';
import styles from './index.module.css';
const Hero = ({
  backgroundImage,
  backgroundFilter,
  backgroundExtension = '.webp',
  text,
  children,
  isSmall,
  color
}) => {
  return <div className={cx(styles.hero, isSmall && styles.isSmall)}>
      <div className={styles.imgCover}>
        <Image className={styles.bg} alt='' priority style={{
        filter: backgroundFilter
      }} src={backgroundImage} {...!backgroundImage.src && {
        src: resizeImg(backgroundImage, 1000, backgroundExtension),
        fill: true,
        sizes: '(max-width: 600px) 300px, 100vw'
      }} />
      </div>
      <div className={styles.heroInner}>
        {!isSmall && <a href='/'>
            <Image className={styles.logo} src='/logo.svg' width={192} height={52} alt='Logo linking home' />
          </a>}
        <h1 className={typography.h1} style={{
        color
      }}>
          {text}
        </h1>
        {children}
      </div>
    </div>;
};
export default Hero;